import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Check, Edit2, Save } from 'lucide-react';

const DailyTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingTask, setEditingTask] = useState(null);
  const [editValues, setEditValues] = useState({});

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('https://centalyayanimda.com/daily_tasks.php');
      if (response.data.success) {
        setTasks(response.data.tasks);
      }
    } catch (error) {
      console.error('Görevler alınırken hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTaskUpdate = async (taskId, isCompleted) => {
    try {
      await axios.post('https://centalyayanimda.com/daily_tasks.php', {
        task_id: taskId,
        is_completed: isCompleted ? 1 : 0  // Boolean değeri 1 veya 0 olarak gönder
      });
      
      // Güncelleme başarılı olduğunda güncel listeyi çek
      fetchTasks();
    } catch (error) {
      console.error('Görev güncellenirken hata oluştu:', error);
    }
  };

  const handleSave = async (taskId) => {
    try {
      await axios.post('https://centalyayanimda.com/daily_tasks.php', {
        task_id: taskId,
        is_completed: tasks.find(t => t.id === taskId).is_completed,
        count_value: editValues.count_value || null,
        note: editValues.note || null
      });
      
      setEditingTask(null);
      fetchTasks();
    } catch (error) {
      console.error('Kaydetme hatası:', error);
    }
  };

  if (loading) return <div>Yükleniyor...</div>;

  return (
    <div className="bg-white shadow-md rounded-lg p-4 max-w-2xl mx-auto">
      <h3 className="text-lg font-semibold mb-3">Günlük To Do List</h3>
      <div className="space-y-2">
        {tasks.map((task) => (
          <div key={task.id} className="flex items-start gap-3 border-b pb-2">
           <button
  onClick={() => handleTaskUpdate(task.id, !task.is_completed)}
  className={`w-5 h-5 rounded border flex items-center justify-center transition-colors ${
    Number(task.is_completed) === 1 ? 'bg-green-500 border-green-500' : 'border-gray-300'
  }`}
>
  {Number(task.is_completed) === 1 && <Check size={14} className="text-white" />}
</button>

            <div className="flex-grow">
              <div className="flex items-center justify-between">
                <span className={task.is_completed ? 'line-through text-gray-500' : 'text-gray-700'}>
                  {task.task_name}
                  {task.count_value && <span className="ml-2 text-sm text-gray-500">({task.count_value} adet)</span>}
                  {task.note && <span className="ml-2 text-sm text-gray-500">({task.note})</span>}
                </span>
                
                {(task.requires_count || task.requires_note) && (
                  <button
                    onClick={() => {
                      if (editingTask === task.id) {
                        handleSave(task.id);
                      } else {
                        setEditingTask(task.id);
                        setEditValues({
                          count_value: task.count_value || '',
                          note: task.note || ''
                        });
                      }
                    }}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {editingTask === task.id ? <Save size={14} /> : <Edit2 size={14} />}
                  </button>
                )}
              </div>

              {editingTask === task.id && (
                <div className="mt-2 space-y-2">
                  {task.requires_count && (
                    <input
                      type="number"
                      value={editValues.count_value}
                      onChange={e => setEditValues({...editValues, count_value: e.target.value})}
                      className="w-24 border rounded px-2 py-1 text-sm"
                      placeholder="Adet"
                    />
                  )}
                  {task.requires_note && (
                    <input
                      type="text"
                      value={editValues.note}
                      onChange={e => setEditValues({...editValues, note: e.target.value})}
                      className="w-full border rounded px-2 py-1 text-sm"
                      placeholder="Not"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyTasks;