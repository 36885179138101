import React, { useState, useEffect } from 'react';
import { Home, Target, TrendingUp, Award, FileText, User, Menu, LogOut } from 'lucide-react';
import Profile from './Profile';
import PDFReader from './PDFReader';
import Performance from './Performance';
import { Link, useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import SalesList from './SalesList';
import axios from 'axios';
import Hedefler from './Hedefler';
import HedefGir from './HedefGir';
import CompanyBalance from './CompanyBalance'; // New import for CompanyBalance page
import DailyTasks from './DailyTasks';


const TableCard = ({ title, children }) => (
  <div className="bg-white shadow-md rounded-lg p-4 mb-4">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    {children}
  </div>
);

const Layout = ({ setIsAuthenticated }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [dailyProduction, setDailyProduction] = useState(0);
  const [monthlyProduction, setMonthlyProduction] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const formatCurrency = (value) => {
    const numberValue = parseFloat(value) / 100;
    return numberValue.toLocaleString('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
    }).replace('₺', '').trim();
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleLogout = async () => {
    try {
      const response = await axios.post('https://centalyayanimda.com/logout.php', {}, {
        withCredentials: true,
      });
  
      if (response.data.success) {
        setIsAuthenticated(false);
        localStorage.removeItem('userRole');
        setTimeout(() => {
          window.location.href = '/#/login';
          window.location.reload();
        }, 100);
      }
    } catch (error) {
      console.error('Çıkış yaparken hata oluştu:', error);
      window.location.href = '/#/login';
      window.location.reload();
    }
  };

  useEffect(() => {
    const fetchProductionData = async () => {
      try {
        const response = await axios.get('https://centalyayanimda.com/get_sales.php');
        const sales = response.data;
  
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
  
        let dailyTotal = 0;
        let monthlyTotal = 0;
  
        sales.forEach(sale => {
          const saleDate = new Date(sale.created_at);
  
          if (
            saleDate.getDate() === today.getDate() &&
            saleDate.getMonth() === today.getMonth() &&
            saleDate.getFullYear() === today.getFullYear()
          ) {
            dailyTotal += parseFloat(sale.gross_premium);
          }
  
          if (saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear) {
            monthlyTotal += parseFloat(sale.gross_premium);
          }
        });
  
        setDailyProduction(formatCurrency(dailyTotal));
        setMonthlyProduction(formatCurrency(monthlyTotal));
      } catch (err) {
        console.error('Veriler alınırken bir hata oluştu', err);
      }
    };
  
    fetchProductionData();
  }, []);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get('https://centalyayanimda.com/check_session.php');
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Kullanıcı rolü alınırken bir hata oluştu:', error);
      }
    };

    fetchUserRole();
  }, []);

  const renderHomePage = () => (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-semibold text-gray-800">Hoş Geldiniz</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <TableCard title="Günlük Üretim">
          <p>Bugünün toplam üretimi: {dailyProduction} TL</p>
        </TableCard>
  
        <TableCard title="Aylık Üretim">
          <p>Bu ayın toplam üretimi: {monthlyProduction} TL</p>
        </TableCard>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <DailyTasks />
      </div>
    </>
  );

  const menuItems = [
    { icon: <Home size={20} />, text: 'Anasayfa', path: '/' },
    { icon: <User size={20} />, text: 'PDF', path: '/pdf' },
    { icon: <TrendingUp size={20} />, text: 'Satışlar', path: '/satislar' },
    { icon: <Target size={20} />, text: 'Hedefler', path: '/hedefler' },
    { icon: <TrendingUp size={20} />, text: 'Performansım', path: '/performans' },
    { icon: <Award size={20} />, text: 'Başarılar', path: '/basarilar' },
    { icon: <FileText size={20} />, text: 'Talepler', path: '/talepler' },
    { icon: <User size={20} />, text: 'Profil', path: '/profil' },
    { icon: <TrendingUp size={20} />, text: 'Şirket Dengesi', path: '/sirket-dengesi' }, // Added Company Balance page to menu
  ];

  if (userRole === 'admin') {
    menuItems.push({ icon: <Target size={20} />, text: 'Hedef Gir', path: '/hedefgir' });
    menuItems.push({ icon: <Target size={20} />, text: 'Primler', path: '/primler' });
  }

  return (
    <div className="flex h-screen bg-gray-100 font-sans">
      <aside className={`bg-blue-700 text-white w-64 min-h-screen p-4 ${menuOpen ? '' : 'hidden'} md:block transition-all duration-300`}>
        <nav>
          <ul>
            {menuItems.map((item, index) => (
              <li key={index} className="mb-2">
                <Link 
                  to={item.path}
                  className={`flex items-center p-2 rounded transition-colors duration-200 ${location.pathname === item.path ? 'bg-blue-600' : 'hover:bg-blue-600'}`}
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                >
                  {item.icon}
                  <span className="ml-2">{item.text}</span>
                </Link>
              </li>
            ))}
            <li className="mb-2">
              <button 
                onClick={handleLogout}
                className="flex items-center p-2 rounded transition-colors duration-200 hover:bg-blue-600 w-full text-left"
              >
                <LogOut size={20} />
                <span className="ml-2">Çıkış Yap</span>
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white shadow-md">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between py-4">
              <div className="flex items-center">
                <button onClick={toggleMenu} className="text-blue-500 focus:outline-none focus:text-blue-700 md:hidden">
                  <Menu size={24} />
                </button>
                <h1 className="text-xl font-bold text-blue-800 ml-2">sigortamolsun.com Yanimda</h1>
              </div>
            </div>
          </div>
        </header>

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">
            <Routes>
              <Route path="/" element={renderHomePage()} />
              <Route path="/profil" element={<Profile />} />
              <Route path="/hedefler" element={<Hedefler />} />
              <Route path="/hedefgir" element={<HedefGir />} />
              <Route path="/performans" element={<Performance />} />
              <Route path="/basarilar" element={<h2>Başarılar Sayfası</h2>} />
              <Route path="/talepler" element={<h2>Talepler Sayfası</h2>} />
              <Route path="/pdf" element={<PDFReader />} />
              <Route path="/satislar" element={<SalesList />} />
              <Route path="/sirket-dengesi" element={<CompanyBalance />} /> {/* Bunu ekleyin */}
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
