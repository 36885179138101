import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);
setDefaultLocale('tr');

const SalesList = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showHistoricalData, setShowHistoricalData] = useState(false);
  const salesPerPage = 5;

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async (month = selectedMonth, year = selectedYear) => {
    try {
      const response = await axios.get('https://centalyayanimda.com/get_sales.php', {
        params: { month, year }
      });
      setSales(response.data);
      setFilteredSales(response.data);
    } catch (error) {
      console.error('Satışlar yüklenirken bir hata oluştu', error);
    }
  };

  useEffect(() => {
    const filtered = sales.filter((sale) => {
      const matchesSearchTerm =
        sale.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.policy_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.product_name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesDateRange =
        (!startDate || new Date(sale.policy_start_date) >= startDate) &&
        (!endDate || new Date(sale.policy_end_date) <= endDate);
      return matchesSearchTerm && matchesDateRange;
    });
    setFilteredSales(filtered);
  }, [searchTerm, sales, startDate, endDate]);

  const formatCurrency = (value) => {
    const numberValue = parseFloat(value) / 100;
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue).replace('₺', '').trim();
  };

  const formatCommission = (value) => {
    const numberValue = parseFloat(value);
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue).replace('₺', '').trim();
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * salesPerPage;
  const currentSales = filteredSales.slice(offset, offset + salesPerPage);

  const productGroups = [
    'Trafik Sigortası',
    'Kasko',
    'Sağlık Sigortası',
    'Konut Sigortası',
    'Dask',
    'Ferdi Kaza & Yol Yardım',
    'İMM',
    'İsyeri'

  ];

  const summaryTables = productGroups.map((group) => {
    const groupSales = sales.filter((sale) => 
      sale.product_name.toLowerCase() === group.toLowerCase()
    );
    const totalAmount = groupSales.reduce((sum, sale) => sum + parseFloat(sale.gross_premium), 0);
    return (
      <div key={group} className="bg-white shadow-md rounded-lg p-4 m-4 w-1/3">
        <h3 className="text-lg font-semibold mb-2">{group}</h3>
        <p>Toplam Satış Tutarı: {formatCurrency(totalAmount)}</p>
        <p>Toplam Satış Adeti: {groupSales.length}</p>
      </div>
    );
  });

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Satışlarım</h2>

      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <div className="flex items-center mb-4">
          <h3 className="text-lg font-semibold mr-4">Satış Dönemi:</h3>
          <button
            onClick={() => setShowHistoricalData(!showHistoricalData)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {showHistoricalData ? 'Güncel Ayı Göster' : 'Geçmiş Satışları İncele'}
          </button>
        </div>

        {showHistoricalData && (
          <div className="flex gap-4 items-center">
            <select
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(parseInt(e.target.value));
                fetchSales(parseInt(e.target.value), selectedYear);
              }}
              className="border rounded py-2 px-3"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(2000, i, 1).toLocaleString('tr-TR', { month: 'long' })}
                </option>
              ))}
            </select>

            <select
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(parseInt(e.target.value));
                fetchSales(selectedMonth, parseInt(e.target.value));
              }}
              className="border rounded py-2 px-3"
            >
              {Array.from(
                { length: 5 },
                (_, i) => new Date().getFullYear() - i
              ).map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>

            {selectedMonth !== new Date().getMonth() + 1 || selectedYear !== new Date().getFullYear() ? (
              <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 ml-4">
                {selectedYear === new Date().getFullYear() ? 
                  `${new Date(2000, selectedMonth-1, 1).toLocaleString('tr-TR', { month: 'long' })} ayı satışlarını görüntülüyorsunuz` :
                  `${selectedYear} yılı ${new Date(2000, selectedMonth-1, 1).toLocaleString('tr-TR', { month: 'long' })} ayı satışlarını görüntülüyorsunuz`
                }
              </div>
            ) : (
              <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 ml-4">
                Güncel ay satışlarını görüntülüyorsunuz
              </div>
            )}
          </div>
        )}
      </div>

      <div className="mb-4 flex gap-4">
        <input
          type="text"
          placeholder="Arama yap..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Başlangıç Tarihi"
          className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          locale="tr"
          dateFormat="dd.MM.yyyy"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="Bitiş Tarihi"
          className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          locale="tr"
          dateFormat="dd.MM.yyyy"
        />
      </div>

      <table className="min-w-full bg-white">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Kayıt Tarihi</th>
            <th className="w-1/4 text-left py-3 px-4 uppercase font-semibold text-sm">Şirket Adı</th>
            <th className="w-1/6 text-left py-3 px-4 uppercase font-semibold text-sm">Poliçe Numarası</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Ürün Adı</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Net Prim</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Brüt Prim</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Başlangıç Tarihi</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Bitiş Tarihi</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Yeni/Yenileme</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Prim</th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {currentSales.map((sale) => (
            <tr key={sale.id}>
              <td className="text-left py-3 px-4">{new Date(sale.created_at).toLocaleDateString('tr-TR')}</td>
              <td className="w-1/4 text-left py-3 px-4">{sale.company_name}</td>
              <td className="w-1/6 text-left py-3 px-4">{sale.policy_number}</td>
              <td className="text-left py-3 px-4">{sale.product_name}</td>
              <td className="text-left py-3 px-4">{formatCurrency(sale.net_premium)}</td>
              <td className="text-left py-3 px-4">{formatCurrency(sale.gross_premium)}</td>
              <td className="text-left py-3 px-4">{new Date(sale.policy_start_date).toLocaleDateString('tr-TR')}</td>
              <td className="text-left py-3 px-4">{new Date(sale.policy_end_date).toLocaleDateString('tr-TR')}</td>
              <td className="text-left py-3 px-4">{sale.new_or_renewal}</td>
              <td className="text-left py-3 px-4">{formatCommission(sale.commission)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"Önceki"}
        nextLabel={"Sonraki"}
        pageCount={Math.ceil(filteredSales.length / salesPerPage)}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        className="flex justify-center mt-4"
        pageClassName="mx-2"
      />

      <div className="mt-8">
        <h3 className="text-xl font-bold mb-4">Ürün Gruplarına Göre Satış Özeti</h3>
        <div className="flex flex-wrap -m-4">
          {summaryTables}
        </div>
      </div>
    </div>
  );
};

export default SalesList;
