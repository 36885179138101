import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Signup from './components/Signup';
import Login from './components/Login';
import AdminSalesPage from './components/AdminSalesPage';
import HedefGir from './components/HedefGir';
import Primler from './components/Primler';
import CompanyBalance from './components/CompanyBalance'; // New import for Company Balance page

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);

  // Oturum ve rol kontrolü
  const checkAuth = async () => {
    try {
      const response = await fetch('https://centalyayanimda.com/check_session.php', {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        setIsAuthenticated(false);
        setUserRole('');
        return;
      }

      const data = await response.json();

      if (data.authenticated && data.role) {
        setIsAuthenticated(true);
        setUserRole(data.role);
        
        // Admin için otomatik yönlendirme
        if (data.role === 'admin' && window.location.hash === '#/login') {
          window.location.hash = '#/admin';
        }
      } else {
        setIsAuthenticated(false);
        setUserRole('');
      }
    } catch (error) {
      console.error('Oturum kontrolü sırasında hata:', error);
      setIsAuthenticated(false);
      setUserRole('');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  // Session kontrolünü periyodik olarak yap
  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticated) {
        checkAuth();
      }
    }, 60000); // Her dakika kontrol et

    return () => clearInterval(interval);
  }, [isAuthenticated]);

  const ProtectedAdminRoute = ({ children }) => {
    if (loading) {
      return <div>Yükleniyor...</div>;
    }

    if (!isAuthenticated || userRole !== 'admin') {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              userRole === 'admin' ? (
                <Navigate to="/admin" replace />
              ) : (
                <Navigate to="/" replace />
              )
            ) : (
              <Login 
                setIsAuthenticated={setIsAuthenticated}
                setUserRole={setUserRole} // Bu satırı ekledik
              />
            )
          }
        />
        <Route path="/kayitola" element={<Signup />} />

        <Route
          path="/admin"
          element={
            <ProtectedAdminRoute>
              <AdminSalesPage />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/hedefgir"
          element={
            <ProtectedAdminRoute>
              <HedefGir />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/primler"
          element={
            <ProtectedAdminRoute>
              <Primler />
            </ProtectedAdminRoute>
          }
        />

<Route
  path="/*"
  element={
    isAuthenticated ? (
      <Layout 
        setIsAuthenticated={setIsAuthenticated} 
        setUserRole={setUserRole}
        userRole={userRole} 
      />
    ) : (
      <Navigate to="/login" replace />
    )
  }
/>
      </Routes>
    </Router>
  );
}

export default App;
