import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const HedefGir = () => {
  const [personnel, setPersonnel] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState('');
  const [product, setProduct] = useState('Kasko');
  const [unitGoal, setUnitGoal] = useState('');
  const [premiumGoal, setPremiumGoal] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  // Admin kontrolü
  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axios.get('https://centalyayanimda.com/check_session.php');
        const { authenticated, role } = response.data;
        if (authenticated && role === 'admin') {
          setIsAdmin(true);
        } else {
          navigate('/'); // Admin değilse anasayfaya yönlendir
        }
      } catch (error) {
        console.error('Yetki kontrolü sırasında bir hata oluştu:', error);
        navigate('/'); // Hata olursa anasayfaya yönlendir
      }
    };

    checkAdmin();
  }, [navigate]);

  // Personel listesini çek
  useEffect(() => {
    const fetchPersonnel = async () => {
      try {
        const response = await axios.get('https://centalyayanimda.com/get_personnel.php');
        setPersonnel(response.data);
      } catch (error) {
        console.error('Personel listesi alınırken bir hata oluştu:', error);
      }
    };

    fetchPersonnel();
  }, []);

  // Hedefi kaydet
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://centalyayanimda.com/hedefler_add.php',
        {
          personnel_id: selectedPersonnel,
          product_name: product,
          unit_goal: parseInt(unitGoal),  // Sayıya çevir
          premium_goal: parseInt(premiumGoal)  // Sayıya çevir
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data.success) {
        alert('Hedef başarıyla eklendi.');
        setSelectedPersonnel('');
        setProduct('Kasko');
        setUnitGoal('');
        setPremiumGoal('');
      } else {
        alert('Hedef eklenirken bir hata oluştu: ' + response.data.message);
      }
    } catch (error) {
      console.error('Hedef eklenirken bir hata oluştu:', error);
      const errorMessage = error.response?.data?.message || error.message;
      alert('Hedef eklenirken bir hata oluştu: ' + errorMessage);
    }
};

  if (!isAdmin) {
    return <div>Yükleniyor...</div>; // Admin değilse ya da yetki kontrolü yapılıyorsa
  }

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Hedef Gir</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Personel Seçin</label>
          <select
            value={selectedPersonnel}
            onChange={(e) => setSelectedPersonnel(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Personel Seçin</option>
            {personnel.map((person) => (
              <option key={person.id} value={person.id}>
                {person.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Ürün Seçin</label>
          <select
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="Kasko">Kasko</option>
            <option value="Trafik Sigortası">Trafik Sigortası</option>
            <option value="Sağlık Sigortası">Sağlık Sigortası</option>
            <option value="Konut Sigortası">Konut Sigortası</option>
            <option value="Dask">Dask</option>
            <option value="Ferdi Kaza & Yol Yardım">Ferdi Kaza & Yol Yardım</option>
            <option value="İMM">İMM</option>
            <option value="İsyeri">İsyeri</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Adet Hedefi</label>
          <input
            type="number"
            value={unitGoal}
            onChange={(e) => setUnitGoal(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Prim Hedefi (TL)</label>
          <input
            type="number"
            value={premiumGoal}
            onChange={(e) => setPremiumGoal(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Hedefi Ekle
        </button>
      </form>
    </div>
  );
};

export default HedefGir;
